import React, { useEffect } from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Layout from '../components/layout';
import { merge } from '../utils/functional';
import { refreshSessionId } from '../utils/session_id';
import { rhythm } from '../utils/typography';

import { colors } from '../components/colors';
import * as cssStyles from './terms.module.css';

import { RenderHead } from "../components/head";
export const Head = () => RenderHead({ title: 'Terms of Use' });



const copyrightYear = () => {
    let d = new Date();
    let y = d.getFullYear();
    return d.getMonth() === 11 ? y + 1 : y;
};


// width: 100% is required for IE to honor the .container bounds

const styles = {
    
    heading: {
        width: '100%',
        margin: `${rhythm(4.5)} 0 0`,
        fontFamily: '"Overpass", sans-serif',
        fontSize: '30px',
        fontWeight: 'bold',
        lineHeight: '1.87',
        color: colors.black
    },

    subHeading: {
        width: '100%',
        margin: '35px 0 0',
        fontFamily: '"Overpass", sans-serif',
        fontSize: '21px',
        fontWeight: '600',
        lineHeight: '1.43',
        color: colors.black
    },

    subSubHeading: {
        width: '100%',
        margin: '20px 0 0',
        fontSize: '16px',
        lineHeight: '1.75',
        letterSpacing: '0.16px',
        color: colors.black
    },

    body: {
        width: '100%',
        margin: '15px 0 0',
        fontSize: '15px',
        lineHeight: '1.93',
        letterSpacing: '0.15px',
        color: colors.black
    },

    link: {
        margin: '0 3px',
        color: colors.black,
        borderBottom: 'solid 1px ' + colors.lightGreen,
        textDecoration: 'none',
        boxShadow: 'none'
    },

    ul: {
        marginBottom: 0
    },

    li: {
        listStyleType: 'disc'
    },

    liSpacing: {
        margin: '0 0 15px'
    }
};


const Terms = ({ location }) => {

    useEffect(() => { refreshSessionId(); }, []);
    
    return (
        <Layout
            location={ location } >                     

            <div
                style={{
                    width: `100%`,
                    overflow: `hidden`,
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `flex-start`,
                    alignItems: `center`
                }} >

                <div
                    className={ cssStyles.container }
                    style={{
                        display: `flex`,
                        flexDirection: `column`,
                        justifyContent: `flex-start`,
                        alignItems: `flex-start`
                    }} >

                    <div
                        style={ styles.heading } >
                        Terms of Use
                    </div>

                    <div
                        style={
                            merge(styles.body,
                                  { fontStyle: 'italic' }
                                 )} >
                        Last updated on January 28, 2023
                    </div>
                    

                    <div
                        style={ styles.subHeading } >
                        Summary
                    </div>

                    <div
                        style={ styles.body } >
                        You (“you”, “user”, “Subscriber” or “Member”) are important to us at 6Pages, Inc. (“6Pages”, “we”, “our” or “us”), and we seek to be fair and even-handed so we can earn your trust. We work to develop websites, applications, products, services and especially content (collectively, “Services”) that we want you to love. In order to run a business that has operating costs, we charge subscription fees for some of our Services, especially access to our proprietary content. We ask our users to, in turn, be responsible in how they use the content, materials and information (“Content”) available on 6Pages.com (“Site”).
                    </div>

                    <div
                        style={ styles.body } >
                        This “Terms of Use” (referred to below as the “Terms”) has been written to describe the conditions under which the Site is being made available to you. These Terms describe important limitations about the way you may use and rely upon Content you find on the Site. By using the Site, you are deemed to have accepted them. If you do not accept the Terms, we’ll be sad to see you go but you may not use the Site.
                    </div>


                    <div
                        style={ styles.subHeading } >
                        Use of Site
                    </div>

                    <div
                        style={ styles.body } >
                        <b>Limited license:</b> We grant you a non-exclusive limited license (“Limited License”) to use the Site and view and download Content publicly available on the Site solely for your personal use and for informational purposes only, provided that you retain all copyright and other proprietary notices contained in the original Content on any copies of the Content. For Subscribers or Members who sign up and provide payment for a term-based subscription (“Subscription”), the Limited License is furthermore extended to allow them to view and download non-public Content accessible on the Site through their own account during the term of their subscription. All the Content on the Site is copyrighted and any unauthorized use of any Content from the Site may violate copyright, trademark and other laws.
                    </div>

                    <div
                        style={ styles.body } >
                        <b>Limitations of use:</b> You may not view or download Content accessed through someone else’s account, and doing so will automatically terminate the Limited License. You may not modify, excerpt or supplement Content available on the Site in any way or create derivative products or content, without written permission. You may not reproduce or publicly display, distribute, perform or otherwise use them for any public or commercial purpose, without written permission. Any use of Content on any other website, database, cloud storage system, searchable repository, software application, or interface in a networked computer environment for any purpose is prohibited. You may not use any of the Content on the Site in a manner that causes the Content so used to be a substitute for the purchase of Content by a 3rd party. You may not use Content in a manner contrary to, or in violation of, any applicable laws.
                    </div>

                    <div
                        style={ styles.body } >
                        If you breach any of these Terms, your Limited License to use the Site and the Content on the Site automatically terminates and you must immediately delete or otherwise destroy any downloaded or printed materials.
                    </div>




                    <div
                        style={ styles.subHeading } >
                        Subscription
                    </div>

                    <div
                        style={ styles.subSubHeading } >
                        Usage
                    </div>

                    <div
                        style={ styles.body } >
                        6Pages Subscriptions are available only to users residing in the United States, Canada, and Australia (“Available Regions”). If you reside outside of these countries, you may not purchase a Subscription. 6Pages collects your country of residence during the sign-up process and you are solely responsible for truthfully and accurately reporting your country of residence. We may block or reverse your purchase based on factors including but not limited to: Your country of residence, the country associated with your payment method, the billing address associated with your payment method, or the country associated with your IP address. For the sake of clarity, whether or not we block or reverse your purchase, you are still solely responsible for reporting your true country of residence.
                    </div>

                    <div
                        style={ styles.body } >
                        Each 6Pages Subscription is for a single user only. Upon registration, you will provide an identifier (such as an email address) and password (identifier and password are, collectively, “ID”). The email address that you provide on registration must correspond to a specific email account with a single user/owner, and not a generic or shared email account. You are not allowed to share your ID or give access to Content through your ID to anyone else. For clarity, we do not allow multiple users within an organization or on a network to use the same ID. We may cancel or suspend your access to the Site if you share your ID, without further obligation to you. You may not create additional registration or subscription accounts for the benefit of others or with the aim of avoiding our use of IDs to control access to and use of the Site.
                    </div>

                    <div
                        style={ styles.body } >
                        You are responsible for all use of the Site made by you or anyone else using your ID and for preventing unauthorized use of your ID. If you believe there has been any breach of security such as the disclosure, theft or unauthorized use of your ID or any payment information, you must notify 6Pages immediately by sending us an email at 
                        <OutboundLink
                            style={ styles.link }
                            href='mailto:hello@6pages.com' >
                            hello@6pages.com
                        </OutboundLink>
                        .
                    </div>

                    
                    <div
                        style={ styles.subSubHeading } >
                        Pricing & Billing
                    </div>

                    <div
                        style={ styles.body } >
                        6Pages charges a recurring (e.g. annual, monthly) fee for access to our Subscription, which will be subject to auto-renewal every period until canceled. As a Subscriber or Member, you may be billed in accordance with one or more of the following circumstances:
                    </div>

                    <div
                        style={ styles.body } >

                        <ul style={ styles.ul } >
                            
                            <li style={ merge(styles.li, styles.liSpacing) } >
                                <b>Recurring rate:</b> You will be billed at our recurring subscription rate when you first sign up for a Subscription and each period thereafter unless and until you cancel your Subscription.
                            </li>

                            <li style={ merge(styles.li, styles.liSpacing) } >
                                <b>Promotional rate:</b> If you sign up under an existing and valid promotion, you will be billed at the promotional rate for the initial period of your Subscription, in accordance with the terms of the promotion. After the initial period, the Subscription will automatically renew at the full recurring subscription rate, and each period thereafter, unless and until you cancel your Subscription. 6Pages reserves the right to limit the number of times an individual or entity can sign up under a given promotion.
                            </li>

                            <li style={ merge(styles.li) } >
                                <b>Complimentary access:</b> 6Pages may, from time to time, offer select users complimentary access to a Subscription for a limited time. The terms and timeframe for complimentary access will be communicated to you during the sign-up process. A payment method will not be collected and you will not be charged on a recurring basis. If you wish to extend your Subscription beyond the timeframe allowed for your complimentary access, you must add a payment method and purchase a Subscription at the then-current recurring subscription rate.
                            </li>
                            
                        </ul>
                        
                    </div>
                    

                    <div
                        style={ styles.body } >
                        6Pages may bill for known applicable taxes at the time of any monetary transaction. However, even in the case that 6Pages does not bill for applicable taxes, you are still responsible for any and all applicable taxes, if any, relating to any transaction.
                    </div>

                    <div
                        style={ styles.body } >
                        Subscription charges are fully earned upon payment and 6Pages is not required to provide refunds or credits for partially used Subscription or trial periods, as applicable. If any fee is not paid in a timely manner, or if 6Pages is unable to process a transaction using the payment method provided, 6Pages reserves the right to revoke access to your account and Subscription.
                    </div>

                    <div
                        style={ styles.body } >
                        If your payment method reaches its expiration date, your continued use of 6Pages constitutes your authorization for us to continue billing that payment method and you remain responsible for any uncollected amounts. You are responsible for making any changes needed to your payment information to maintain a valid and unexpired payment method.
                    </div>

                    <div
                        style={ styles.body } >
                        6Pages may, at its sole discretion and at any point, make changes to the price of Subscription, its offerings, and renewal periods. Your continued use of 6Pages constitutes your acceptance of any changes to your Subscription, including the Subscription fee and auto-renewal. You may view the expiration date or renewal date of your Subscription, as applicable, by logging in and visiting your account page.
                    </div>

                    <div
                        style={ styles.body } >
                        <b>Cancellation:</b> You must cancel your Subscription before it renews to avoid being billed for the next period. Although you may notify us of your intention to cancel at any time, such notice will only take effect at the end of the Subscription period and no refund will be provided. You do not have any right to cancel your Subscription or any part of it until the end of the Subscription period. You will continue to have access to your Subscription until the cancellation takes effect.
                    </div>

                    <div
                        style={ styles.body } >
                        <b>Termination:</b> 6Pages reserves the right to suspend or terminate your Subscription at any time. If you breach any of these Terms, your Subscription may be terminated without notice and without further obligation. We may also suspend or terminate your Subscription if for some reason we are prevented from providing services to you by circumstances beyond our control.
                    </div>

                    <div
                        style={ styles.body } >
                        All customer questions on Pricing & Billing should be directed to
                        <OutboundLink
                            style={ styles.link }
                            href='mailto:help@6pages.com' >
                            help@6pages.com
                        </OutboundLink>
                        .
                    </div>
                    
                    

                    <div
                        style={ styles.subHeading } >
                        Disclaimers
                    </div>

                    <div
                        style={ styles.body } >
                        Content and views expressed on the Site are provided for general information purposes only on an “as is” basis without warranties of any kind, including warranties of merchantability, fitness for a particular purpose, or non-infringement of intellectual property, and subject to revision at any time. Content is not intended to address your particular requirements or constitute any form of advice, recommendation, representation, endorsement or arrangement by us. Content is not intended to be and should not be relied upon in making decisions with respect to any specific investment, purchase, sale or other matter. We furthermore do not warrant the completeness, accuracy or timeliness of the Content on the Site. We may make changes to the Content on the Site, including descriptions of our Services and their prices, at any time without notice. Content at the Site may be out of date, and we make no commitment to update the Content or other Services. We do not advocate or endorse any service or product mentioned in the Content. Any action taken based on information provided is strictly at your own risk. Our obligations with respect to our Content and other Services are governed solely by the agreements under which they are provided and nothing on the Site should be construed to alter such agreements.
                    </div>

                    <div
                        style={ styles.body } >
                        Not all Services may be available in your country. Reach out to 
                        <OutboundLink
                            style={ styles.link }
                            href='mailto:hello@6pages.com' >
                            hello@6pages.com
                        </OutboundLink>
                        for information regarding offerings that may be available to you.
                    </div>


                    <div
                        style={ styles.body } >
                        Applicable law may not allow the exclusion of implied warranties, so the above exclusion may or may not apply to you.
                    </div>


                    <div
                        style={ styles.subHeading } >
                        Limitations of liabilities
                    </div>

                    <div
                        style={ styles.body } >
                        In no event shall we, our vendors, our partners or other third parties mentioned on the Site be liable for any indirect, special, consequential, incidental, punitive or exemplary damages, costs, expenses or losses, including, without limitation, loss of business, business interruption, lost profits or lost data, arising out of the use, inability to use, or resulting from use of the Site, the Content or other Services available through the Site, or any websites linked to the Site, or arising out of these Terms, any related document, or otherwise related to the subject matter hereof or thereof, however based on warranty, contract, tort or any other legal theory of liability and whether or not we have been advised of the possibility of such damages. If your use of the Content or other Services from the Site results in the need for servicing, repair or correction of equipment, software or data, you assume all costs thereof. Applicable law may not allow the exclusion or limitation of incidental or consequential damages, so these limitations or exclusions may or may not apply to you.
                    </div>


                    <div
                        style={ styles.subHeading } >
                        Additional information
                    </div>

                    <div
                        style={ styles.subSubHeading } >
                        Age limitation
                    </div>
                    
                    
                    <div
                        style={ styles.body } >
                        The Site is not directed to persons under the age of 16. If you become aware that your child has provided us with personal information without your consent, please contact us at
                        <OutboundLink
                            style={ styles.link }
                            href='mailto:hello@6pages.com' >
                            hello@6pages.com
                        </OutboundLink>
                        . We do not knowingly collect or solicit information from, market to, or provide services to persons under 16 years old.
                    </div>


                    <div
                        style={ styles.subSubHeading } >
                        General
                    </div>
                    
                    
                    <div
                        style={ styles.body } >
                        We administer the Site from our offices in the State of California, USA. We make no representation that Content or other Services on the Site are appropriate or available for use outside Available Regions. Access to Content or Services from territories where their contents are illegal is prohibited. You may not use or export or re-export the Content or other Services on the Site or any copy or adaptation in violation of any applicable laws or regulations including, without limitation, United States export laws and regulations. If you choose to access the Site from outside the United States, you do so on your own initiative and are responsible for compliance with applicable local laws. These Terms will be governed by and construed in accordance with the laws of the State of Delaware, without giving effect to any principles of conflicts of laws.
                    </div>

                    
                    <div
                        style={ styles.subSubHeading } >
                        3rd-party sites
                    </div>
                    
                    
                    <div
                        style={ styles.body } >
                        Links on the Site to 3rd-party websites are provided solely as a convenience to you. If you use these links, you will leave the Site. We have not reviewed all of these 3rd-party sites and do not control and are not responsible for any of these sites or their content. Thus, we do not endorse or make any representations about them or any information, software, other products, or materials found there. If you decide to access any of the 3rd-party websites linked on the Site or take any action based on information found there, you do this entirely at your own risk.
                    </div>


                    <div
                        style={ styles.subSubHeading } >
                        Changes to these terms
                    </div>
                    
                    
                    <div
                        style={ styles.body } >
                        Although most changes are likely to be minor, we may revise these Terms from time to time (e.g. to account for changes in the law or changes in our Services). If we make changes, we will notify you by revising the date at the top of this policy. Because changes to these Terms are binding on you, we encourage you to frequently check the Terms of Use page on the Site for any changes. Your continued use of the Site after a change to these Terms will be subject to the updated policy, and any further use will be governed by our most current Terms. Certain provisions of these Terms may be superseded by expressly designated legal notices or terms located on other pages of the Site.
                    </div>


                    <div
                        style={ styles.subSubHeading } >
                        Contacting us
                    </div>
                    
                    
                    <div
                        style={ styles.body } >
                        If you have any questions about these Terms, please send us a note at
                        <OutboundLink
                            style={ styles.link }
                            href='mailto:hello@6pages.com' >
                            hello@6pages.com
                        </OutboundLink>
                        .
                    </div>


                    <div
                        style={ styles.subSubHeading } >
                        Copyrights and trademarks
                    </div>
                    
                    
                    <div
                        style={ styles.body } >
                        All Content available on the Site is copyrighted © { copyrightYear() } 6Pages, Inc. All rights reserved. 6Pages is a registered trademark of 6Pages, Inc. in the United States. The display of the 6Pages trademark on the Site shall not be construed as an implied license to use such trademark.
                    </div>
                    
                    
                    <div
                        style={{
                            width: `1px`,
                            height: `115px`,
                            background: `transparent`
                        }} />

                </div>
                
            </div>
            
        </Layout>
);
};

export default Terms;
